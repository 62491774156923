@use "constants/styles.scss";

.answer-card {
    width: 100%;
    height: 100%;
    list-style: none;
  

  & .answer-card-row:not(:first-of-type) {
    height: 30px;
  }

  & .answer-card-row {
    width: 100%;
    font-size: 15px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    &:hover {
      background: styles.$light-grey;

      .answer-card .answer-card-status--review {
        display: block;
      }
    }

    & div:nth-child(2) {
      color: styles.$white;
      font-weight: 600;
    }

    &:first-of-type {
      padding: 20px 0 15px 0;
      border-bottom: 1px solid styles.$black;
      color: styles.$black;

      &:hover {
        background: initial;
      }

      & div:nth-child(2) {
        color: styles.$black;
      }
    }

    &:nth-of-type(2) {
      margin-top: 15px;

      & div:nth-child(2) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    &:last-of-type {
      margin-bottom: 15px;
      height: 30px;

      & div:nth-child(2) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .answer-card-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    &.answer-column {
      width: 100%;
      max-width: 100px;
      margin: 0 auto;
    }

    & .more-button {
      text-decoration: underline;
      font-size: 12px;
      color: black;
    }

    &.correct {
      background-color: styles.$green;
    }

    &.incorrect {
      background-color: styles.$red;
    }

    &.sortable-column-header {
      cursor: pointer;

      & .sortable-column-icon {
        margin-left: 5px;
      }
    }
  }

  & .answer-card-status {
    font-size: 13px;
    position: relative;
    cursor: pointer;

    &--review {
      display: none;
    }
  }
}
